<template>
  <div class="contact-info-container">
    
    <div class="contact-list">
      <div v-for="(contact, index) in contacts" :key="index" class="contact-card">
        <img :src="contact.image" :alt="contact.name" class="contact-image" />
        <div class="contact-name">{{ contact.name }}</div>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  name: 'ContactInfo',
  data() {
    return {
      contacts: [
        {
          name: 'WhatsApp',
          image: require('@/assets/whatsapp.svg'),
          link: 'https://wa.me/1234567890'
        },
        {
          name: 'Wechat',
          image: require('@/assets/wechat.svg'),
          link: 'https://wa.me/1234567890'
        },
        {
          name: 'Telegram',
          image: require('@/assets/telegram.svg'),
          link: 'https://t.me/1234567890'
        }
      ]
    }
  }
}
</script>

<style scoped>
.contact-info-container {
  padding: 40px;
  background-color: #1a1a1a;
  min-height: 100vh;
  color: #fff;
}

.page-title {
  font-size: 50px;
  text-align: center;
  margin-bottom: 20px;
  color: #f39800;
}

.contact-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-card {
  background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 450px;
  height: 550px;
  padding: 30px;
  margin: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-card:hover {
  transform: scale(1.05);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.4);
}

.contact-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 16px;
  margin-bottom: 30px;
  border: 4px solid #f39800;
}

.contact-name {
  font-size: 32px;
  color: #f0f0f0;
  font-weight: bold;
}
</style> 