import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import BuyPage from '../views/BuyPage.vue'
import SellPage from '../views/SellPage.vue'
import ReceiptBuyPage from '../views/ReceiptBuyPage.vue'
import ReceiptSellPage from '../views/ReceiptSellPage.vue'
import PendingOrders from '../views/PendingOrders.vue'
import DashboardView from '@/views/Dashboard.vue'
import SetQuotes from '@/views/SetQuotes.vue'
import MemberLogin from '@/views/MemberLogin.vue'
import MemberPage from '@/views/MemberPage.vue'
import LoginPage from '@/views/LoginPage.vue' // 引入 LoginPage 組件
import FinancePage from '@/views/FinancePage.vue'
import ViewPrice from '@/views/ViewPrice.vue'
import FinancialAccountsPage from '@/views/FinancialAccountsPage.vue';
import DisplayData from '@/views/DisplayData.vue';
import PriceQuoteManager from '@/views/PriceQuoteManager.vue'; // 引入 PriceQuoteManager 组件
import MobilePriceView from '@/views/MobilePriceView.vue';
import DisplayDataManager from '@/views/DisplayDataManager.vue' // 引入 DisplayDataManager 组件
import RecentOrders from '@/views/RecentOrders.vue'
import ContactInfo from '@/views/ContactInfo.vue' // 引入 ContactInfo 组件
import MemberList from '@/views/MemberList.vue'
const routes = [
  {
    path: '/',
    redirect: '/dashboard' // 重定向到登录页面
  },
  {
    path: '/buy/:allowAdjust?',
    name: 'Buy',
    component: BuyPage,
    props: route => ({
      allowAdjust: route.params.allowAdjust === 'true' || false, // 默认值为 false
    }),
  },
  {
    path: '/sell/:allowAdjust?',
    name: 'Sell',
    component: SellPage,
    props: route => ({
      allowAdjust: route.params.allowAdjust === 'true' || false, // 默认值为 false
    }),
  },
  {
    path: '/receipt-buy/:orderNumber',
    name: 'ReceiptBuy',
    component: ReceiptBuyPage,
    props: true
  },
  {
    path: '/receipt-sell/:orderNumber',
    name: 'ReceiptSell',
    component: ReceiptSellPage,
    props: true
  },
  {
    path: '/pending-orders',
    name: 'PendingOrders',
    component: PendingOrders,
    meta: { requiresAuth: true } // 如果需要身份验证
  },
  {
    path: '/print-receipt/:orderNumber',
    name: 'PrintReceipt',
    component: () => import('../views/PrintReceipt.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/set-quotes',
    name: 'SetQuotes',
    component: SetQuotes,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/member-login',
    name: 'MemberLogin',
    component: MemberLogin
  },
  {
    path: '/member',
    name: 'MemberPage',
    component: MemberPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/finance',
    name: 'Finance',
    component: FinancePage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/price',
    name: 'ViewPrice',
    component: ViewPrice,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/financial-accounts',
    name: 'FinancialAccounts',
    component: FinancialAccountsPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/display/:channel',
    name: 'DisplayData',
    component: DisplayData,
    props: true,
    meta: { hideHeader: true }
  },
  {
    path: '/display-data-manager',
    name: 'DisplayDataManager',
    component: DisplayDataManager,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/price-quote-manager',
    name: 'PriceQuoteManager',
    component: PriceQuoteManager,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/mprice',
    name: 'MobilePriceView',
    component: MobilePriceView,
  },
  {
    path: '/trade-guide',
    name: 'TradeGuide',
    component: () => import('@/views/TradeGuide.vue'),
    meta: {
      title: '交易指南',
      icon: 'Guide' // 使用 Element Plus 的 Guide 图标
    }
  },
  {
    path: '/order/:orderNumber',
    name: 'OrderDetail',
    component: () => import('@/views/OrderDetail.vue'),
    props: true
  },
  {
    path: '/recent-orders',
    name: 'RecentOrders',
    component: RecentOrders
  },
  {
    path: '/contact-info',
    name: 'ContactInfo',
    component: ContactInfo
  },
  {
    path: '/member-list',
    name: 'MemberList',
    component: MemberList
  },
  {
    path: '/owner-out/:waitTime',
    name: 'OwnerOut',
    component: () => import('@/views/OwnerOut.vue'),
    props: true
  },
  {
    path: '/price-display-screen',
    name: 'PriceDisplayScreen',
    component: () => import('@/views/PriceDisplayScreen.vue')
  },
  {
    path: '/store-info-display',
    name: 'StoreInfoDisplay',
    component: () => import('@/views/StoreInfoDisplay.vue'),
    meta: {
      hideHeader: true // 添加meta属性来控制是否隐藏header
    }
  },
  {
    path: '/transfer-monitoring',
    name: 'TransferMonitoring',
    component: () => import('@/views/TransferMonitoring.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!userStore.isLoggedIn) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
