<template>
  <div class="recent-orders">
    <!-- <h1 class="page-title">最近交易</h1> -->
    <div class="orders-row">
      <div v-for="(order, index) in demoOrders.slice(0, 3).reverse()" :key="index" class="order-card" :class="order.type">
        <div class="order-type-badge" :class="{'order-type sell': order.type === 'sell'}">
          {{ order.type === 'buy' ? '買入' : '賣出' }}
        </div>
        
        <div class="order-content">
          <div class="currency-section">
            <div class="currency-item" v-if="order.type === 'buy'">
              <img src="@/assets/icons/money.png" alt="Cash" class="currency-icon"/>
              <div class="amount-display">
                <span class="currency-label">HKD</span>
                <span class="amount-value">{{ Number(order.amount).toLocaleString('en-US', {maximumFractionDigits: 0}) }}</span>
              </div>
            </div>
            <div class="currency-item" v-else>
              <img src="@/assets/icons/usdtmoney.png" alt="USDT" class="currency-icon"/>
              <div class="amount-display">
                <span class="currency-label">USDT</span>
                <span class="amount-value">
                  {{ Number(Math.floor(order.usdt_amount)).toLocaleString('en-US') }}
                  <span class="decimal-part">.{{ (order.usdt_amount % 1).toFixed(4).substring(2) }}</span>
                </span>
              </div>
            </div>
            <div class="exchange-arrow">
              <el-icon><ArrowDown /></el-icon>
            </div>
            <div class="currency-item" v-if="order.type === 'buy'">
              <img src="@/assets/icons/usdtmoney.png" alt="USDT" class="currency-icon"/>
              <div class="amount-display">
                <span class="currency-label">USDT</span>
                <span class="amount-value">
                  {{ Number(Math.floor(order.usdt_amount)).toLocaleString('en-US') }}
                  <span class="decimal-part">.{{ (order.usdt_amount % 1).toFixed(4).substring(2) }}</span>
                </span>
              </div>
            </div>
            <div class="currency-item" v-else>
              <img src="@/assets/icons/money.png" alt="Cash" class="currency-icon"/>
              <div class="amount-display">
                <span class="currency-label">HKD</span>
                <span class="amount-value">{{ Number(order.amount).toLocaleString('en-US', {maximumFractionDigits: 0}) }}</span>
              </div>
            </div>
          </div>

          <!-- <div class="order-flow">
            <template v-if="order.type === 'buy'">
              <div class="flow-step">
                <div class="icon-item">
                  <img src="@/assets/icons/money.png" alt="Cash" class="currency-icon"/>
                  <span class="step-text">港幣現金</span>
                </div>
                <div class="icon-arrow">→</div>
                <div class="icon-item">
                  <img src="@/assets/icons/store.png" alt="Store" class="currency-icon"/>
                  <span class="step-text">店鋪收款</span>
                </div>
              </div>
              <div class="flow-step">
                <div class="icon-item">
                  <img src="@/assets/icons/usdtmoney.png" alt="USDT" class="currency-icon"/>
                  <span class="step-text">USDT</span>
                </div>
                <div class="icon-arrow">→</div>
                <div class="icon-item">
                  <img src="@/assets/icons/coinwallet.png" alt="Wallet" class="currency-icon"/>
                  <span class="step-text">錢包地址</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="flow-step">
                <div class="icon-item">
                  <img src="@/assets/icons/usdtmoney.png" alt="USDT" class="currency-icon"/>
                  <span class="step-text">USDT</span>
                </div>
                <div class="icon-arrow">→</div>
                <div class="icon-item">
                  <img src="@/assets/icons/store.png" alt="Store" class="currency-icon"/>
                  <span class="step-text">店鋪收款</span>
                </div>
              </div>
              <div class="flow-step">
                <div class="icon-item">
                  <img src="@/assets/icons/store.png" alt="Store" class="currency-icon"/>
                  <span class="step-text">店鋪付款</span>
                </div>
                <div class="icon-arrow">→</div>
                <div class="icon-item">
                  <img src="@/assets/icons/cash.png" alt="Cash" class="currency-icon"/>
                  <span class="step-text">港幣現金</span>
                </div>
              </div>
            </template>
          </div> -->
        </div>

        <div class="order-footer">
          <span class="time">{{ formatTime(order.created_at) }}</span>
          <span class="status" :class="order.status">{{ order.status }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { ElIcon } from 'element-plus';
import { ArrowDown } from '@element-plus/icons-vue';
import { getRecentPreOrders } from '@/utils/preOrderService';

export default {
  name: 'RecentOrders',
  components: {
    ElIcon,
    ArrowDown
  },
  setup() {
    const demoOrders = ref([]);
    let intervalId = null;

    const fetchOrders = async () => {
      try {
        const response = await getRecentPreOrders();
        if (response.code === 0) {
          const newOrders = response.data.map(order => ({
            order_number: order.order_number,
            type: order.order_type,
            status: order.status,
            created_at: order.created_at,
            amount: order.amount,
            usdt_amount: order.usdt_amount
          }));

          // 比较新旧数据，如果不同则更新
          if (JSON.stringify(newOrders) !== JSON.stringify(demoOrders.value)) {
            demoOrders.value = newOrders;
          }
        } else {
          console.error('获取订单失败:', response.message);
        }
      } catch (error) {
        console.error('获取订单出错:', error);
      }
    };

    onMounted(() => {
      fetchOrders();
      intervalId = setInterval(fetchOrders, 15000); // 每15秒刷新一次
    });

    onBeforeUnmount(() => {
      clearInterval(intervalId);
    });

    const formatTime = (utcTime) => {
      const date = new Date(utcTime);
      date.setHours(date.getHours() + 8); // 转换为 UTC+8
      return date.toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' });
    };

    return {
      demoOrders,
      formatTime
    };
  }
};
</script>

<style scoped>
.recent-orders {
  background-color: #1a1a1a;
  min-height: 100vh;
  padding: 20px;
  color: #fff;
}

.page-title {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.orders-row {
  display: flex;
  justify-content: space-between;
  height: 90%;
}

.order-card {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
}

.order-card.buy {
  border-left: 5px solid #67C23A;
}

.order-card.sell {
  border-left: 5px solid #E74C3C;
}

.order-card:hover {
  transform: translateY(-5px);
}

.order-type-badge {
  background-color: #67C23A;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  font-size: 50px;
}

.order-type.sell {
  background-color: #E74C3C; /* 红色用于卖出 */
  color: #ffffff; /* 白色文字 */
}

.amount-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.currency {
  font-size: 18px;
  color: #ccc;
}

.amount {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.divider {
  font-size: 28px;
  color: #fff;
}

.order-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.step-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.icon-item img {
  width: 28px;
  height: 28px;
}

.icon-arrow {
  font-size: 28px;
  color: #fff;
}

.order-footer {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #ccc;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #409EFF;
  color: #fff;
}

.status.processing {
  background-color: #F39C12;
}

.status.completed {
  background-color: #2ECC71;
}

.status.failed {
  background-color: #E74C3C;
}

.currency-icon {
  width: 100px;
  height: 100px;
}

.currency-label {
  font-size: 40px;
  color: #ccc;
}

.amount-value {
  font-size: 70px;
  font-weight: bold;
  color: #fff;
}

.decimal-part {
  font-size: 24px;
  color: #ccc;
}

.exchange-arrow {
  font-size: 28px;
  color: #fff;
  margin: 100px 10px;
}

.flow-step {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.step-text {
  font-size: 34px;
  color: #ccc;
}

</style> 