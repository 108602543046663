<template>
  <div class="display-data-manager">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="18" class="content-col">
        <h1>{{ $t('message.displayDataManager') }}</h1>
        <div class="button-group">
          <el-button class="theme-button" @click="openCreateDialog">{{ $t('message.createDisplayData') }}</el-button>
          <el-button class="theme-button" @click="copyToken">复制用户Token</el-button>
        </div>
        <el-table :data="displayDataList" style="width: 100%" v-loading="loading" align="center">
          <el-table-column prop="order_number" label="Order Number" width="180" align="center"></el-table-column>
          <el-table-column label="Content" align="center">
            <template #default="scope">
              <el-select v-model="scope.row.content" placeholder="请选择内容" @change="updateContent(scope.row)">
                <el-option label="互动屏-店内报价屏" value="https://order.pcdao.org/price"></el-option>
                <el-option label="招牌Logo" value="https://uprice.pcdao.org/logo"></el-option>
                <el-option label="店外-报价屏" value="https://uprice.pcdao.org/index3"></el-option>
                <el-option label="订单列表" value="https://order.pcdao.org/recent-orders"></el-option>
                <el-option label="联系方式" value="https://order.pcdao.org/contact-info"></el-option>
                <el-option label="店主外出" value="https://order.pcdao.org/owner-out/10"></el-option>
                <el-option label="多档价格屏" value="https://order.pcdao.org/price-display-screen"></el-option>
                <el-option label="店外招牌加报价屏" value="https://order.pcdao.org/store-info-display"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="display_scheme" label="Display Scheme" width="180" align="center">
            <template #default="scope">
              <el-select v-model="scope.row.display_scheme" placeholder="请选择显示方案" @change="updateDisplayScheme(scope.row)">
                <el-option label="ORDER" value="ORDER"></el-option>
                <el-option label="URL" value="URL"></el-option>
                <el-option label="JSON" value="JSON"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="channel" label="Channel" width="120" align="center"></el-table-column>
          <el-table-column label="Actions" width="260" align="center">
            <template #default="scope">
              <div style="display: flex; justify-content: center; gap: 10px;">
                <el-button size="mini" class="theme-button" @click="openEditDialog(scope.row)">{{ $t('message.edit') }}</el-button>
                <el-popconfirm
                  :title="$t('message.confirmDelete')"
                  @confirm="deleteDisplayData(scope.row.id)"
                >
                  <template #reference>
                    <el-button size="mini" class="delete-button">{{ $t('message.delete') }}</el-button>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 创建/编辑对话框 -->
        <el-dialog :title="isEditing ? $t('message.editDisplayData') : $t('message.createDisplayData')" v-model="dialogVisible">
          <el-form :model="formData" :rules="rules" ref="form">
            <el-form-item label="Order Number" prop="order_number">
              <el-input v-model="formData.order_number"></el-input>
            </el-form-item>
            <el-form-item label="Content" prop="content">
              <el-input v-model="formData.content" type="textarea" :rows="4"></el-input>
            </el-form-item>
            <el-form-item label="Display Scheme" prop="display_scheme">
              <el-select v-model="formData.display_scheme" placeholder="请选择显示方案">
                <el-option label="ORDER" value="ORDER"></el-option>
                <el-option label="URL" value="URL"></el-option>
                <el-option label="JSON" value="JSON"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Channel" prop="channel">
              <el-input v-model="formData.channel"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <el-button @click="dialogVisible = false">{{ $t('message.cancel') }}</el-button>
            <el-button type="primary" class="theme-button" @click="submitForm">{{ $t('message.submit') }}</el-button>
          </template>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import displayDataService from '@/utils/displayDataService';
import SidebarMenu from '@/components/SidebarMenu.vue';
import { useUserStore } from '@/stores/user';

export default {
  name: 'DisplayDataManager',
  components: {
    SidebarMenu
  },
  setup() {
    const displayDataList = ref([]);
    const loading = ref(false);
    const dialogVisible = ref(false);
    const isEditing = ref(false);
    const form = ref(null);
    const formData = ref({
      id: '',
      order_number: '',
      content: '',
      display_scheme: '',
      channel: ''
    });
    const rules = {
      content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      display_scheme: [{ required: true, message: '请选择显示方案', trigger: 'change' }],
      channel: [{ required: true, message: '请输入频道', trigger: 'blur' }]
    };

    const fetchDisplayData = async () => {
      loading.value = true;
      try {
        const response = await displayDataService.getAllDisplayData();
        console.log('获取数据:', response);
        displayDataList.value = response || [];
      } catch (error) {
        console.error('获取数据失败:', error);
        ElMessage.error('获取数据失败');
      } finally {
        loading.value = false;
      }
    };

    const openCreateDialog = () => {
      isEditing.value = false;
      formData.value = {
        id: '',
        order_number: '',
        content: '',
        display_scheme: '',
        channel: ''
      };
      dialogVisible.value = true;
    };

    const openEditDialog = (row) => {
      isEditing.value = true;
      formData.value = { ...row };
      dialogVisible.value = true;
    };

    const insertUrl = (url) => {
      formData.value.content = url;
    };

    const updateContent = async (row) => {
      try {
        await displayDataService.updateDisplayData(row.id, { content: row.content, display_scheme: 'URL' });
        ElMessage.success('内容更新成功');
      } catch (error) {
        console.error('更新内容失败:', error);
        ElMessage.error('更新内容失败');
      }
    };

    const copyToken = () => {
      const userStore = useUserStore();
      const token = userStore.jwtToken; // 假设 token 存储在 userStore 中
      if (token) {
        navigator.clipboard.writeText(token).then(() => {
          ElMessage.success('Token 已复制到剪贴板');
        }).catch(err => {
          console.error('复制 token 时出错:', err);
          ElMessage.error('复制 token 失败');
        });
      } else {
        ElMessage.error('未找到用户 token');
      }
    };

    const updateDisplayScheme = async (row) => {
      try {
        await displayDataService.updateDisplayData(row.id, { display_scheme: row.display_scheme });
        ElMessage.success('显示方案更新成功');
      } catch (error) {
        console.error('更新显示方案失败:', error);
        ElMessage.error('更新显示方案失败');
      }
    };

    const submitForm = async () => {
      if (!form.value) return;
      
      form.value.validate(async (valid) => {
        if (valid) {
          try {
            if (isEditing.value) {
              console.log('更新数据:', formData.value);
              await displayDataService.updateDisplayData(formData.value.id, formData.value);
              ElMessage.success('更新成功');
            } else {
              await displayDataService.createOrUpdateDisplayData(formData.value);
              ElMessage.success('创建成功');
            }
            dialogVisible.value = false;
            fetchDisplayData();
          } catch (error) {
            console.error('提交表单失败:', error);
            ElMessage.error('提交失败');
          }
        }
      });
    };

    const deleteDisplayData = async (id) => {
      try {
        await displayDataService.deleteDisplayData(id);
        ElMessage.success('删除成功');
        fetchDisplayData();
      } catch (error) {
        console.error('删除失败:', error);
        ElMessage.error('删除失败');
      }
    };

    onMounted(() => {
      fetchDisplayData();
    });

    return {
      displayDataList,
      loading,
      dialogVisible,
      isEditing,
      formData,
      form,
      rules,
      openCreateDialog,
      openEditDialog,
      insertUrl,
      updateContent,
      copyToken,
      updateDisplayScheme,
      submitForm,
      deleteDisplayData
    };
  }
};
</script>

<style scoped>
.el-table {
  margin-top: 20px;
}

.el-dialog {
  width: 500px;
}

.el-form-item {
  margin-bottom: 20px;
}

.theme-button {
  background-color: #f39800;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.theme-button:hover {
  background-color: #d87a00;
}

.delete-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #c0392b;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
</style> 