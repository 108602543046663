export default {
    message: {
        language: '语言',
        title: 'Rice加密货币找换店',
        type: '交易类型',
        buy: '买入',
        sell: '卖出',
        amount: '港币金额',
        submit: '提交',
        amountError: '金额必须是100的倍数且至少{minAmount}港币',
        exchangeRateError: '获取汇率失败，请稍后再试',
        walletAddress: '钱包地址',
        enterWalletAddress: '输入您的钱包地址',
        chain: '链名称',
        exchangeRate: '汇率',
        amountHint: '金额必须是100的倍数，最低为 {minAmount} 港币',
        usdtAmount: 'USDT金额',
        orderNumber: '订单号',
        paymentAddress: '付款地址',
        copyAddress: '复制地址',
        copyFailed: '复制失败',
        addressCopied: '地址复制成功',
        reset: '重新下单',
        returnToHome: '返回首页',
        walletAddressError: '钱包地址是必需的',
        invalidTronAddress: '无效的Tron钱包地址',
        invalidBscAddress: '无效的Binance Smart Chain (BSC)钱包地址',
        orderSubmitted: '订单提交成功',
        submitFailed: '订单提交失败。',
        adjustAmount: '调整金额',
        enterUSDT: '输入USDT金额',
        viewReceipt: '查看收据',
        pendingOrders: "待处理订单",
        noPendingOrders: "没有待处理订单",
        statusPending: "待处理",
        statusCompleted: "已完成",
        statusClosed: "已关闭",
        edit: "编辑",
        markCompleted: "标记为已完成",
        closeOrder: "关闭订单",
        resetOrder: "重置订单",
        uploadImage: "拍照",
        receiptTitle: 'Rice 加密货币找换所',
        orderTime: '订单创建日期',
        printTime: '收据打印时间',
        receiptDeclaration: '收据声明',
        receiptDeclarationContent: '我已核实上述交易详情和付款信息，并确认其准确性。一旦Rice商店将USDT转移到提供的地址，任何后续的USDT变动将完全由您负责。请确保您的钱包地址安全，以避免任何潜在的资金损失。此外，请确认您完全理解并承认此购买是由您本人进行的，并且您对这一行为承担全部责任。',
        printReceipt: '打印收据',
        orderType: "交易类型",
        buyOrder: "买入",
        sellOrder: "卖出",
        storeAddress: "商店地址",
        storeAddressContent: "香港九龙旺角弥敦道608号总统商业中心1楼150A铺",
        sellReceiptDeclarationContent: '我已核实上述交易详情和收款信息，并确认其准确性。我已收到应付现金，并确认USDT与现金的兑换已完全结清。请确认此交易仅由您本人在店内进行。请注意，在现场确认此交易后，Rice不对您离开场所后发生的任何问题负责。',
        startScanningError: '启动扫描时发生错误',
        deviceNotFound: '未找到请求的设备',
        cameraNotSupported: '您的设备不支持相机功能',
        scanningError: '扫描过程中发生错误',
        startScanning: '开始扫描',
        stopScanning: '停止扫描',
        dashboard: '仪表盘',
        todayTransactions: "今日交易",
        todayProfit: "今日利润",
        completedOrders: '已完成订单',
        viewPendingOrders: '查看待处理订单',
        status: '状态',
        selectStatus: '选择状态',
        startTime: '开始时间',
        endTime: '结束时间',
        startDate: '开始日期',
        endDate: '结束日期',
        search: '搜索',
        settings: '设置',
        buyPrice: '买入价',
        sellPrice: '卖出价',
        setQuotes: '设置报价',
        save: '保存',
        memberLogin: '会员登录',
        email: '电子邮件',
        password: '密码',
        login: '登录',
        memberPage: '会员页面',
        welcome: '欢迎',
        sendCode: '发送验证码',
        verificationCode: '验证码',
        buyUSDT: '购买USDT',
        hkdToUSDT: '港币 兑换 USDT',
        orderManagement: '订单管理',
        saveSuccess: '保存成功',
        noPermission: '您没有权限执行此操作',
        operationFailed: '操作失败，请稍后再试',
        logout: '登出',
        loading: '加载中...',
        receiveAddress: '收款地址',
        createdAt: '创建日期',
        fee: '手续费',
        transactionHash: '交易哈希',
        remark: '备注',
        sendAddress: '发送地址',
        all: '全部',
        amountInput: '请输入USDT金额或港币金额',
        sellUSDT: '卖出USDT',
        USDTToHKD: 'USDT 兑换 港币',
        hkdFee: '港币手续费',
        feeApplied: "订单金额低于 {amount} HKD，将收取 {fee} HKD 手续费",
        binanceOnlineOrder: '币安线上订单',
        finance: '财务',
        revenue: '收入',
        expenses: '支出',
        profit: '利润',
        undistributedProfit: '未分配利润',
        typeFinance: '类型',
        categoryFinance: '类别',
        subcategoryFinance: '子类别',
        projectFinance: '项目',
        remarksFinance: '备注',
        addFinanceRecord: '新增财务记录',
        todayHKDBuy: '今日港币买入',
        todayHKDSell: '今日港币卖出',
        profitRate: '利润率',
        loadingDashboardData: '加载仪表板数据中...',
        empty: '关闭',
        out: '离开',
        promptStatus: '提示状态',
        otherInfo: '其他信息',
        outMessage: '离开信息',
        selectPromptStatus: '请选择提示状态',
        monthProfit: '本月利润',
        viewPrice: '用戶交互屏',
        busyMessage: '有客信息',
        closedMessage: '休息信息',
        openMessage: '营业信息',
        chinese: '中文',
        english: '英文',
        traditionalChinese: '繁体中文',
        financialAccounts: '财务账户',
        rmbAmount: '人民币金额',
        cancel: '取消',
        enterPlatformOrGasFee: '请输入平台手续费或Gas费用',
        platformGasFeeUSDT: '平台/Gas费用 (USDT)',
        enterHKDFee: '请输入港币手续费',
        chainType: '链类型',
        sendAddressType: '发送地址类型',
        editOrder: '编辑订单',
        receiveAddressType: '接收地址类型',
        selectReceiveAddressType: '请选择接收地址类型',
        exchange: '交易所',
        wallet: '钱包',
        selectSendAddress: '请选择发送地址',
        paymentChannelReceiveAddress: '支付通道接收地址',
        enterUSDTAmount: '请输入USDT金额',
        enterHKDAmount: '请输入港币金额',
        enterRateValue: '请输入汇率值',
        editSuccess: '编辑成功',
        buyPriceLarge: '大额买入价',
        sellPriceLarge: '大额卖出价',
        largeOrderThreshold: '大额订单阈值',
        annualizedReturn: '年化回報率',
        selectChain: "选择链",
        fetchAddressesError: "获取地址列表失败",
        accountTransfer: '账户转账',
        frequentlyUsed: '常用',
        sourceAccount: '来源账户',
        targetAccount: '目标账户',
        hkdAmount: '港币金额',
        confirm: '确认',
        accountTransferSuccess: '账户转账成功',
        orderAmountNotMultipleOfHundred: '订单金额不是100的倍数',
        orderAmountError: '订单最小金额为 {amount} 港币',
        usdtOrHkdAction: '输入订单金额',
        viewImage: '查看图片',
        close: '关闭',
        rotateImage: '旋转180度',
        selfServiceOrder: '自助下单',
        priceQuoteManager: '价格报价管理',
        enterMemberId: '请输入会员ID',
        memberId: '会员ID',
        viewDetail: '查看详情',
        displayData: '显示数据',
        noData: '没有数据',
        enterChannel: '请输入通道',
        channel: '通道',
        createDisplayData: '创建显示数据',
        editDisplayData: '编辑显示数据',
        displayDataManager: '显示数据管理',
        content: '内容',
        displayScheme: '显示方案',
        actions: '操作',
        delete: '删除',
        confirmSendToDisplay: '确认发送',
        confirmSendToDisplayMessage: '确认发送订单到显示器',
        selectDisplay: '请选择显示器',
        sendToDisplay: '发送订单到显示器',
        memberList: '会员列表',
        memberIdQRCode: '会员ID二维码',
        viewQRCode: '查看二维码',
        memberManagement: '会员管理',
        memberManagementTitle: '会员管理',
        memberManagementDescription: '会员管理',
        transferMonitoring: '转账监控',
    },
    menu: {
        dashboard: '仪表盘',
        setQuotes: '设置报价',
        price: '价格',
        tradeGuide: '交易指南'
    },
    price: {
        buyUSDT: '买入USDT',
        hkdToUSDT: '港币 兑换 USDT',
        sellUSDT: '卖出USDT',
        USDTToHKD: 'USDT 兑换 港币',
        minOrderAmount: '订单最小金額: {amount}',
        feeApplied: '订单金額低於 {amount} HKD，將收取 {fee} HKD 手續費',
        largeOrderThreshold: '大額',
        receiveUsdtAddressQrCode: '接收USDT的地址二維碼',
        chainName: '鏈名稱',
        receiveUsdtAmount: '接收USDT金額',
        orderDetails: '訂單詳情',
        orderNumber: '訂單號',
        orderType: '訂單類型',
        customerReceiveHkd: '客戶收港幣',
        customerReceiveUsdt: '客戶收USDT',
        largeOrderMessage: '订单金额达到 {amount} HKD，享受大额价格 {rate} HKD/USDT',
        printReceipt: '打印收據',
        printA4Receipt: '打印A4收據',
        fullscreen: '全屏',
        shopName: 'Rice 加密貨幣找換所',
        home: '首頁',
        buy: '買入',
        sell: '賣出',
        open: '正在營業',
        busy: '有客在店',
        out: '臨時外出',
        closed: '休息放假',
        noFeeAbove500: '卖出USDT金额超过500HKD，免收手续费',
        hkdAmount: '港币金额',
        exchangeRate: '汇率',
        fee: '手续费',
        amount: '金额',
        createdTime: '下单时间',
        printRecordAddedSuccess: '打印记录添加成功',
        printRecordAddFailed: '添加打印记录失败',
        usdtAmount: 'USDT金额',
        targetVolume: '交易量',
        level: '等级', 
        price: '价格',
    },
    guide: {
        buyUsdtTitle: '买入USDT',
        buyUsdtDescription: '买入USDT，享受便捷的交易体验。',
        sellUsdtTitle: '卖出USDT',
        sellUsdtDescription: '卖出USDT，享受便捷的交易体验。',
        buyStep1Title: '步骤1：确认兑换金额',
        buyStep1Description: '确认兑换金额（港币或USDT数量）并下单。',
        buyStep2Title: '步骤2：给店铺港币现金',
        buyStep2Description: '将港币现金交给店铺。',
        buyStep3Title: '步骤3：店铺转入地址',
        buyStep3Description: '店铺将USDT转入客户接收的地址。',
        sellStep1Title: '步驟1：確認兌換金額',
        sellStep1Description: '確認兌換金額（港幣或USDT數量）並下單。',
        sellStep2Title: '步驟2：客戶轉USDT給店鋪',
        sellStep2Description: '將USDT轉給店鋪。',
        sellStep3Title: '步驟3：店鋪給港幣現金到客戶',
        sellStep3Description: '店鋪將港幣現金交給客戶。',
        pressF11: '按 F11 键切换全屏显示'
    }
};
