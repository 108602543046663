import  api  from './api';

/**
 * 获取今日最近的预订单
 * @returns {Promise<Object>} - 返回包含最近预订单数据的 Promise
 */
export const getRecentPreOrders = async () => {
  try {
    const response = await api.get('/pre_orders/recent');
    return response.data;
  } catch (error) {
    console.error('Error retrieving recent pre-orders:', error);
    throw error;
  }
};

/**
 * 创建预订单
 * @param {Object} preOrderData - 包含预订单信息的对象
 * @param {string} preOrderData.order_number - 订单号
 * @param {number} preOrderData.amount - 金额
 * @param {string} preOrderData.direction - 方向
 * @returns {Promise<Object>} - 返回包含响应数据的 Promise
 */
export const createPreOrder = async (preOrderData) => {
  try {
    const response = await api.post('/api/pre_orders', {
      order_number: preOrderData.order_number,
      amount: preOrderData.amount,
      direction: preOrderData.direction
    });
    return response.data;
  } catch (error) {
    console.error('Error creating pre-order:', error);
    throw error;
  }
};

/**
 * 更新预订单状态
 * @param {string} orderNumber - 订单号
 * @param {Object} updateData - 包含更新信息的对象
 * @returns {Promise<Object>} - 返回包含响应数据的 Promise
 */
export const updatePreOrder = async (orderNumber, updateData) => {
  try {
    const response = await api.put(`/api/pre_orders/${orderNumber}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating pre-order:', error);
    throw error;
  }
};

/**
 * 获取预订单详情
 * @param {string} orderNumber - 订单号
 * @returns {Promise<Object>} - 返回包含预订单详情的 Promise
 */
export const getPreOrderDetails = async (orderNumber) => {
  try {
    const response = await api.get(`/api/pre_orders/${orderNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving pre-order details:', error);
    throw error;
  }
}; 



/**
 * 创建收款监控
 * @param {Object} monitoringData - 监控数据对象
 * @param {string} monitoringData.order_number - 订单号
 * @param {string} monitoringData.chain_type - 链类型
 * @param {string} monitoringData.coin_type - 币种类型
 * @param {string} monitoringData.address - 钱包地址
 * @param {number} monitoringData.amount - 金额
 * @returns {Promise<Object>} - 返回包含响应数据的 Promise
 */
export const createMonitoring = async (monitoringData) => {
  try {
    // 创建 FormData 对象用于发送表单数据
    const formData = new FormData();
    formData.append('order_number', monitoringData.order_number);
    formData.append('chain_type', monitoringData.chain_type);
    formData.append('coin_type', monitoringData.coin_type); 
    formData.append('address', monitoringData.address);
    formData.append('amount', monitoringData.amount);

    const response = await api.post('/monitoring/create', formData);
    return response.data;
  } catch (error) {
    console.error('创建收款监控失败:', error);
    throw error;
  }
};


/**
 * 根据订单号获取监控记录
 * @param {string} orderNumber - 订单号
 * @returns {Promise<Object>} - 返回包含监控记录的 Promise
 */
export const getMonitoringByOrderNumber = async (orderNumber) => {
  try {
    const response = await api.get(`/monitoring/${orderNumber}`);
    console.log("getMonitoringByOrderNumber response:",response.data);
    return response.data;
  } catch (error) {
    console.error('获取监控记录失败:', error);
    throw error;
  }
};

/**
 * 获取待处理的监控记录
 * @returns {Promise<Object>} - 返回包含待处理监控记录的 Promise
 */
export const getPendingMonitoring = async () => {
  try {
    const response = await api.get('/monitoring/pending');
    console.log("getPendingMonitoring response:", response);
    return response.data;
  } catch (error) {
    console.error('获取待处理监控记录失败:', error);
    throw error;
  }
};


/**
 * 更新监控记录
 * @param {number} id - 监控记录ID 
 * @param {Object} updateData - 需要更新的数据
 * @param {string} [updateData.status] - 状态
 * @param {number} [updateData.amount] - 金额
 * @returns {Promise<Object>} - 返回更新结果
 */
export const updateMonitoring = async (id, updateData) => {
  try {
    const response = await api.put(`/monitoring/${id}`, updateData);
    console.log("updateMonitoring response:", response.data);
    return response.data;
  } catch (error) {
    console.error('更新监控记录失败:', error);
    throw error;
  }
};
