<template>
  <div class="container">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="20">
        <div class="member-management-page">
          <h1>会员后台管理</h1>
          
          <!-- 搜索表单 -->
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="姓名">
              <el-input v-model="searchForm.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input v-model="searchForm.phone" placeholder="请输入电话"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="searchForm.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>

          <!-- 会员列表表格 -->
          <el-table :data="members" style="width: 100%" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="member_id" label="会员ID" width="200">
              <template #default="scope">
                <el-button type="text" @click="openEditMemberDialog(scope.row)">
                  {{ scope.row.member_id }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="会员ID二维码" width="120">
              <template #default="scope">
                <el-popover
                  placement="right"
                  trigger="hover"
                  width="200"
                >
                  <template #reference>
                    <el-button type="primary" link>查看二维码</el-button>
                  </template>
                  <div style="text-align: center">
                    <qrcode-vue :value="String(scope.row.member_id)" :size="180" level="H"></qrcode-vue>
                    <div>会员ID: {{ scope.row.member_id }}</div>
                    <div>姓名: {{ scope.row.name }}</div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="total_buy_amount" label="总买入金额" width="150" :formatter="formatCurrency"></el-table-column>
            <el-table-column prop="total_sell_amount" label="总卖出金额" width="150" :formatter="formatCurrency"></el-table-column>
            
            <el-table-column prop="membership_level_id" label="会员等级" width="100"></el-table-column>
            <el-table-column prop="remark" label="备注" width="200"></el-table-column>
            <el-table-column prop="name" label="姓名" width="150"></el-table-column>
            <el-table-column prop="wallet_address" label="钱包地址" width="200"></el-table-column>
            <el-table-column prop="phone" label="电话" width="120"></el-table-column>
            <el-table-column prop="whatsapp" label="WhatsApp" width="150"></el-table-column>
            <el-table-column prop="telegram" label="Telegram" width="120"></el-table-column>
            <el-table-column prop="email" label="邮箱" width="180"></el-table-column>
            <el-table-column prop="user_id" label="用户ID" width="100"></el-table-column>
            <el-table-column prop="promo_code" label="推广码" width="120"></el-table-column>           
            <el-table-column prop="commission_balance" label="佣金余额" width="120"></el-table-column>

            
            
            <el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
          </el-table>

          <!-- 分页器 -->
          <div class="pagination-container">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>

          <!-- 会员表单对话框 -->
          <MemberForm
            v-model:visible="isMemberFormVisible"
            :isEditing="isEditing"
            :memberData="selectedMember"
            @refresh="fetchMembers"
            @submit="handleSubmit"
          />
        </div>

        <!-- 添加会员按钮 -->
        <el-button
          type="success"
          class="add-member-button"
          @click="openAddMemberDialog"
        >
          添加会员
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import memberService from '@/utils/memberService';
import SidebarMenu from '@/components/SidebarMenu.vue';
import QrcodeVue from "vue-qrcode";
import MemberForm from '@/components/MemberForm.vue';

export default {
  name: 'MemberList',
  components: {
    SidebarMenu,
    QrcodeVue,
    MemberForm
  },
  setup() {
    const members = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const loading = ref(false);
    const isMemberFormVisible = ref(false);
    const isEditing = ref(false);
    const selectedMember = ref({});
    const searchForm = ref({
      name: '',
      phone: '',
      email: '',
      membershipLevelId: ''
    });

    const fetchMembers = async () => {
      try {
        loading.value = true;
        const params = {
          page: currentPage.value,
          pageSize: pageSize.value,
          ...searchForm.value
        };
        const response = await memberService.getAllMembers(params);
        members.value = response.data.members;
        total.value = response.data.total;
      } catch (error) {
        ElMessage.error('获取会员列表失败');
      } finally {
        loading.value = false;
      }
    };

    const handleSearch = () => {
      currentPage.value = 1;
      fetchMembers();
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
      fetchMembers();
    };

    const handleSizeChange = (size) => {
      pageSize.value = size;
      currentPage.value = 1;
      fetchMembers();
    };

    const openAddMemberDialog = () => {
      isEditing.value = false;
      selectedMember.value = {};
      isMemberFormVisible.value = true;
    };

    const openEditMemberDialog = (member) => {
      isEditing.value = true;
      selectedMember.value = { ...member };
      isMemberFormVisible.value = true;
    };

    const handleSubmit = async (formData) => {
      try {
        if (isEditing.value) {
          const response = await memberService.updateMember(formData);
          console.log('更新会员响应:', response);
          if (response.code === 404) {
            ElMessage.error('未找到会员');
            return;
          }
          ElMessage.success('会员信息更新成功');
        } else {
          const response = await memberService.createMember(formData);
          console.log('创建会员响应:', response);
          if (response.code === 404) {
            ElMessage.error('未找到会员');
            return;
          }
          ElMessage.success('会员创建成功');
        }
        isMemberFormVisible.value = false;
        fetchMembers();
      } catch (error) {
        console.error('提交表单失败:', error);
        if (error.response?.code === 404) {
          ElMessage.error('未找到会员');
        } else {
          ElMessage.error('提交失败');
        }
      }
    };

    const formatCurrency = (row, column) => {
      const value = row[column?.property];
      return '$'+parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    onMounted(() => {
      fetchMembers();
    });

    return {
      members,
      currentPage,
      pageSize,
      total,
      loading,
      searchForm,
      isMemberFormVisible,
      isEditing,
      selectedMember,
      handleSearch,
      handlePageChange,
      handleSizeChange,
      openAddMemberDialog,
      openEditMemberDialog,
      handleSubmit,
      formatCurrency
    };
  }
};
</script>

<style scoped>
.member-management-page {
  padding: 20px;
}

.search-form {
  margin-bottom: 20px;
}

.el-table {
  margin-top: 20px;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.add-member-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>