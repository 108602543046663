import api from './api';

/**
 * 获取运营财务参数
 * @returns {Promise<Object>} - 返回包含运营财务参数数据的 Promise
 */
export const getOperationalFinancialParameters = async () => {
  try {
    const response = await api.get('/financial/operational_parameters');
    console.log("getOperationalFinancialParameters",response.data);
    return response.data;
  } catch (error) {
    console.error('获取运营财务参数失败:', error);
    throw error;
  }
};
