<template>
  <div>
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="20" style="padding-left: 20px;">
        <h1>金融賬戶列表</h1>

       

        <el-row>
      <el-col :span="16">
        <el-row :gutter="20" style="margin-bottom: 20px;">
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>銀行卡合計餘額</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalBankCardBalance) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>現金餘額(HKD)</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalCashBalance) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>現金餘額(USD)</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalCashBalanceUSD) }} USD
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>現金餘額(CNY)</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalCashBalanceCNY) }} CNY
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>交易所與錢包餘額</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalWalletExchangeBalance) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>全部餘額</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalWalletExchangeBalance + totalCashBalance + totalBankCardBalance + (totalCashBalanceUSD * getExchangeRate('USD'))) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>USDT 單位成本</span>
                </div>
              </template>
              <div class="card-content">
                {{ usdtUnitCost }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>總投入資金</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(financialStats) }} HKD                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>分紅USDT</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalDividendUsdt) }} USDT
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>運營金額</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(operationalBalance) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" style="margin-top: 20px;">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>USDT佔比 ({{ formatNumberWithCommasNoDecimals(operationalUsdt) }} HKD)</span>
                </div>
              </template>
              <div class="card-content">
                <el-progress :percentage="Number((operationalUsdt / operationalBalance * 100).toFixed(2))" :format="formatPercentage" />
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" style="text-align: right; margin-top: 20px;">
        <el-button type="primary" @click="openDialog">操作資金</el-button>
        <el-button type="success" @click="refreshData" style="margin-left: 10px;">刷新數據</el-button>
      </el-col>
    </el-row>
    
        <el-dialog v-model="dialogVisible" title="操作資金">
          <el-form :model="form" label-width="120px">
            <el-form-item label="操作類型">
              <el-radio-group v-model="form.operation_type" @change="updateAccountOptions">
                <el-radio label="buy">客戶買入</el-radio>
                <el-radio label="sell">客戶賣出</el-radio>
                <el-radio label="internal_transfer_hkd">內部轉帳(HKD)</el-radio>
                <el-radio label="internal_transfer_usdt">內部轉帳(USDT)</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="來源賬戶ID">
              <el-select v-model="form.source_account_id" placeholder="選擇來源賬戶">
                <el-option
                  v-for="account in sourceAccounts"
                  :key="account.id"
                  :label="`${account.account_type_code === 'CASH' ? '現金' : 
                            account.account_type_code === 'BANK' ? '銀行卡' :
                            account.account_type_code === 'EXCHANGE' ? '交易所' :
                            account.account_type_code === 'WALLET' ? '錢包' : 
                            account.account_type_code} - ${account.name} (餘額: ${
                            account.account_type_code === 'WALLET' || account.account_type_code === 'EXCHANGE' 
                              ? `${formatNumberWithCommasNoDecimals(account.balance_usdt_available)} USDT`
                              : `${formatNumberWithCommasNoDecimals(account.balance)} ${account.currency_code}`
                          })`"
                  :value="account.id"
                ></el-option>
              </el-select>
              
            </el-form-item>
            <el-form-item label="來源金額(HKD)">
              <el-input v-model="form.source_amount" :disabled="form.operation_type === 'internal_transfer_usdt'">
                <template #append>
                  <span style="width: 60px; text-align: center;">HKD</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="form.operation_type !== 'internal_transfer_hkd'" label="目標賬戶ID">
              <el-radio-group v-model="form.target_account_id">
                <el-radio
                  v-for="account in targetAccounts"
                  :key="account.id"
                  :label="account.id"
                >
                  {{ `${account.account_type_code === 'WALLET' ? '錢包' : '交易所'} - ${account.name} (${formatNumberWithCommasNoDecimals(account.balance_usdt_available)} USDT)` }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="form.operation_type === 'internal_transfer_hkd'" label="目標賬戶ID">
              <el-select v-model="form.target_account_id" placeholder="選擇目標賬戶">
                <el-option
                  v-for="account in targetAccounts"
                  :key="account.id"
                  :label="`${account.account_type_code === 'CASH' ? '現金' : 
                            account.account_type_code === 'BANK' ? '銀行卡' :
                            account.account_type_code === 'EXCHANGE' ? '交易所' :
                            account.account_type_code === 'WALLET' ? '錢包' : 
                            account.account_type_code} - ${account.name} (餘額: ${formatNumberWithCommasNoDecimals(account.balance)} ${account.currency_code})`"
                  :value="account.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="目標金額(USDT)">
              <el-input v-model="form.target_amount" :disabled="form.operation_type === 'internal_transfer_hkd'">
                <template #append>
                  <span style="width: 60px; text-align: center;">USDT</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="當前匯率" v-if="form.operation_type !== 'internal_transfer_hkd' && form.operation_type !== 'internal_transfer_usdt'">
              <el-input v-model="currentRate">
                <template #append>
                  <span style="width: 60px; text-align: center;">
                    HKD/USDT
                  </span>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <template v-slot:footer>
            <el-button type="danger" @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitOperation">提交</el-button>
          </template>
        </el-dialog>
        <el-dialog v-model="editDialogVisible" title="編輯賬戶">
          <el-form :model="editForm" label-width="120px">
            <el-form-item label="名稱">
              <el-input v-model="editForm.name"></el-input>
            </el-form-item>
            <el-form-item label="類型">
              <el-select v-model="editForm.account_type_code" placeholder="選擇類型">
                <el-option 
                  v-for="accountType in accountTypes" 
                  :key="accountType.code" 
                  :label="accountType.type_name" 
                  :value="accountType.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="餘額">
              <el-input v-model="editForm.balance"></el-input>
            </el-form-item>
            <el-form-item label="幣種">
              <el-select v-model="editForm.currency_code" placeholder="選擇幣種">
                <el-option 
                  v-for="currency in currencyList" 
                  :key="currency.currency_code"
                  :label="`${currency.currency_name} (${currency.currency_code})`"
                  :value="currency.currency_code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <template v-slot:footer>
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitEdit">提交</el-button>
          </template>
        </el-dialog>


        <div style="display: flex; align-items: center; margin-bottom: 20px;">
          <el-button 
            v-for="currency in currencyList" 
            :key="currency.currency_code" 
            :type="selectedCurrency === currency.currency_code ? 'primary' : 'default'" 
            @click="selectedCurrency = currency.currency_code; console.log('选中的货币:', currency.currency_name || '未定义')"
            style="margin-right: 10px;">
            {{ currency.currency_name }} ({{ currency.currency_code }})
          </el-button>
        </div>
        
        <el-table :data="filteredAccounts" stripe style="width: 100%">
          <el-table-column prop="id" label="ID" width="60">
            <template #default="{ row }">
              <span :style="{
                backgroundColor: operationalAccounts.some(account => account.id === row.id) ? '#c2e7b0' : 'transparent',
                padding: '2px 6px',
                borderRadius: '4px',
                color: operationalAccounts.some(account => account.id === row.id) ? '#2c3e50' : '#606266'
              }">
                {{ row.id }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名稱" width="200" />
          <el-table-column prop="account_type_code" label="類型" width="80">
            <template #default="{ row }">
              {{ getAccountTypeName(row.account_type_code) }}
            </template>
          </el-table-column>
          <el-table-column label="餘額" width="150" align="right">
            <template #default="{ row }">
              <span style="font-size: 16px;font-weight: bold;">{{ formatNumberWithCommasNoDecimals(row.balance) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="估計HKD" width="150" align="right">
            <template #default="{ row }">
              <span style="font-size: 16px;font-weight: bold;">${{ formatNumberWithCommasNoDecimals(row.balance * getExchangeRate(row.currency_code)) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="幣種" width="120">
            <template #default="{ row }">
              {{ getCurrencyName(row.currency_code) }}
            </template>
          </el-table-column>
          <el-table-column label="银行卡信息" width="300">
            <template #default="{ row }">
              <span v-if="bankAccounts.length > 0 && bankAccounts.find(account => account.financial_account_id === row.id)">
                {{ bankAccounts.find(account => account.financial_account_id === row.id).bank_name }} - 
                {{ bankAccounts.find(account => account.financial_account_id === row.id).card_number }} -
                {{ bankAccounts.find(account => account.financial_account_id === row.id).bank_id }}
                <template v-if="bankAccounts.find(account => account.financial_account_id === row.id).fps_phone">
                  - fps:{{ bankAccounts.find(account => account.financial_account_id === row.id).fps_phone }}
                </template>
              </span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <el-button type="primary" size="small" @click="editAccount(row)">編輯</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { ElMessage } from 'element-plus';
import api from '@/utils/api';
import SidebarMenu from '@/components/SidebarMenu.vue';
import {
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";

import { PRICE_API_URL } from "@/apiConfig";
import axios from "axios";


import { getOperationalFinancialParameters } from '@/utils/financial';


const accounts = ref([]);
const cashAccounts = ref([]);
const operationalAccounts = ref([]);
const operationalBalance = ref(0);

const operationalUsdt = ref(0);



const bankCardAccounts = ref([]);
const sourceAccounts = ref([]);
const targetAccounts = ref([]);
const dialogVisible = ref(false);
const form = ref({
  operation_type: '',
  source_account_id: '',
  source_amount: '',
  target_account_id: '',
  target_amount: ''
});

const usdtUnitCost = ref(0);
const buyPrice = ref(0);
const sellPrice = ref(0);
const currentRate = ref(''); 
const currencyList = ref([]);
const bankAccounts = ref([]);
const accountTypes = ref([]);
const financialStats = ref(null);
const totalDividendUsdt = ref(0);

const selectedCurrency = ref('all');



const filteredAccounts = computed(() => {
  if (selectedCurrency.value === 'all') {
    return accounts.value;
  }
  return accounts.value.filter(account => account.currency_code === selectedCurrency.value && account.is_frequently_used);
});

const getCurrencyName = (code) => {
  const currency = currencyList.value.find(c => c.currency_code === code);
  return currency ? `${currency.currency_name} (${currency.currency_code})` : code;
};

const getExchangeRate = (code) => {
  const currency = currencyList.value.find(c => c.currency_code === code);
  return currency ? currency.exchange_rate : 1;
};


const totalBankCardBalance = computed(() => {
  return bankCardAccounts.value.reduce((sum, account) => sum + parseFloat(account.balance), 0);
});

const totalCashBalance = computed(() => {
  return cashAccounts.value
    .filter(account => account.currency_code === 'HKD') // 只计算货币代码为 HKD 的账户
    .reduce((sum, account) => sum + parseFloat(account.balance), 0);
});

const totalCashBalanceUSD = computed(() => {
  return cashAccounts.value
    .filter(account => account.currency_code === 'USD') // 只计算货币代码为 USD 的账户
    .reduce((sum, account) => sum + parseFloat(account.balance), 0);
});

const totalCashBalanceCNY = computed(() => {
  return cashAccounts.value
    .filter(account => account.currency_code === 'CNY') // 只计算货币代码为 CNY 的账户
    .reduce((sum, account) => sum + parseFloat(account.balance), 0);
});
  
const totalWalletExchangeBalance = computed(() => {
  return accounts.value
    .filter(account => account.account_type_code === 'WALLET' || account.account_type_code === 'EXCHANGE')
    .reduce((sum, account) => sum + parseFloat(account.balance) * getExchangeRate(account.currency_code), 0);
});

const editDialogVisible = ref(false);
const editForm = ref({
  id: '',
  name: '',
  account_type_code: '',
  balance: '',
  balance_usdt_available: '',
  balance_usdt_unavailable: '',
  currency_code: ''
});

const getAccountTypeName = (code) => {
  const accountType = accountTypes.value.find(type => type.code === code);
  return accountType ? accountType.type_name : code;
};

onMounted(() => {
  refreshData();

});

async function refreshData() {
  try {
    getFinancialData();
    getFinancialStatistics();

    const priceResponse = await axios.get(PRICE_API_URL);
    buyPrice.value = priceResponse.data.buyPrice;
    sellPrice.value = priceResponse.data.sellPrice;

    currentRate.value = sellPrice.value;

    const usdtCostResponse = await api.get('/get_usdt_unit_cost');
    console.log(usdtCostResponse.data.data.unit_cost.toFixed(4));
    usdtUnitCost.value = parseFloat(usdtCostResponse.data.data.unit_cost.toFixed(4));

    const response = await api.get('/financial_accounts', { params: { is_frequently_used: true } });
    console.log(response.data);
    accounts.value = response.data.data;
    
    cashAccounts.value = accounts.value.filter(account => account.account_type_code === 'CASH');
    bankCardAccounts.value = accounts.value.filter(account => account.account_type_code === 'BANK');
    updateAccountOptions();


    getOperationalFinancialParameters().then(response => {
      if (response.data) {
        const operationalAccountIds = response.data.map(item => item.financial_account_id);
        operationalAccounts.value = accounts.value.filter(account => 
          operationalAccountIds.includes(account.id)
        );
        operationalBalance.value = operationalAccounts.value.reduce((sum, account) => 
          sum + (parseFloat(account.balance) * getExchangeRate(account.currency_code)), 0
        );

        operationalUsdt.value = operationalAccounts.value.reduce((sum, account) => 
          account.currency_code === 'USDT' ? sum + parseFloat(account.balance) * getExchangeRate(account.currency_code) : sum, 0
        );
      }
    });


  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

function updateAccountOptions() {
  if (form.value.operation_type === 'internal_transfer_hkd') {
    sourceAccounts.value = [...cashAccounts.value, ...bankCardAccounts.value];
    targetAccounts.value = [...cashAccounts.value, ...bankCardAccounts.value];
  } else if (form.value.operation_type === 'internal_transfer_usdt') {
    sourceAccounts.value = accounts.value.filter(account => account.account_type_code === 'WALLET' || account.account_type_code === 'EXCHANGE');
    targetAccounts.value = accounts.value.filter(account => account.account_type_code === 'WALLET' || account.account_type_code === 'EXCHANGE');
  } else {
    sourceAccounts.value = [...cashAccounts.value, ...bankCardAccounts.value];
    targetAccounts.value = accounts.value.filter(account => account.account_type_code === 'WALLET' || account.account_type_code === 'EXCHANGE');
    
    if (form.value.operation_type === 'buy') {
      currentRate.value = buyPrice.value;
    } else if (form.value.operation_type === 'sell') {
      currentRate.value = sellPrice.value;
    }
  }
}

function openDialog() {
  console.log("Dialog should open");
  dialogVisible.value = true;
}

function submitOperation() {
  if (form.value.operation_type !== 'internal_transfer_hkd' && form.value.operation_type !== 'internal_transfer_usdt') {
    const calculatedHKDAmount = parseFloat(form.value.target_amount) * parseFloat(currentRate.value);
    const enteredHKDAmount = parseFloat(form.value.source_amount);

    console.log(parseFloat(form.value.target_amount));
    console.log(parseFloat(currentRate.value));
    const largerAmount = Math.max(calculatedHKDAmount, enteredHKDAmount);
    const smallerAmount = Math.min(calculatedHKDAmount, enteredHKDAmount);
    const errorRate = (largerAmount - smallerAmount) / smallerAmount;
    console.log('計算金額:', calculatedHKDAmount);
    console.log('輸入金額:', enteredHKDAmount);
    console.log('誤差率:', errorRate);

    if (errorRate > 0.05) {
      ElMessage({
        message: '匯率計算誤差超過 5%，請檢查輸入的金額。',
        type: 'error',
      });
      return;
    }
  }

  const apiEndpoint = form.value.operation_type.includes('internal_transfer') ? '/internal_transfer' : '/shop_fund_operation';
  const payload = {
    operation_type: form.value.operation_type,
    source_account_id: form.value.source_account_id,
    source_amount: form.value.source_amount,
    target_account_id: form.value.target_account_id,
    target_amount: form.value.target_amount,
    amount: form.value.operation_type === 'internal_transfer_hkd' ? form.value.source_amount : form.value.operation_type === 'internal_transfer_usdt' ? form.value.target_amount : form.value.source_amount,
    currency: form.value.operation_type === 'internal_transfer_hkd' ? 'HKD' : 'USDT'
  };

  console.log(payload);
  console.log(apiEndpoint);

  api.post(apiEndpoint, payload)
    .then(response => {
      console.log("Operation successful:", response.data);
      dialogVisible.value = false;
      refreshData();
      ElMessage({
        message: '操作成功！',
        type: 'success',
      });
    })
    .catch(error => {
      console.error("Error during operation:", error);
      ElMessage({
        message: '操作失敗，請重試。',
        type: 'error',
      });
    });
}

function editAccount(row) {
  editForm.value = { ...row };
  editDialogVisible.value = true;
}

function submitEdit() {
  console.log(editForm.value);
  api.post('/edit_financial_account', editForm.value)
    .then(response => {
      console.log("Edit successful:", response.data);
      editDialogVisible.value = false;
      refreshData();
      ElMessage({
        message: '更新成功！',
        type: 'success',
      });
    })
    .catch(error => {
      console.error("Error during edit:", error);
      ElMessage({
        message: '更新失敗，請重試。',
        type: 'error',
      });
    });
}

async function getFinancialData() {
  try {
    const response = await api.get('/financial_data');
    if (response.data.code === 0) {
      const { currencies, bank_accounts, account_types } = response.data.data;
      currencyList.value = currencies;
      bankAccounts.value = bank_accounts;
      accountTypes.value = account_types;

      console.log(currencyList.value);
      console.log(bankAccounts.value);
      console.log(accountTypes.value);

      ElMessage({
        message: '獲取金融數據成功',
        type: 'success'
      });

    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error('獲取金融數據失敗:', error);
    ElMessage({
      message: '獲取金融數據失敗，請重試',
      type: 'error'
    });
  }
}

async function getFinancialStatistics() {
  try {
    const response = await api.get('/financial/statistics');
    console.log('獲取金融統計數據', response.data);
    if (response.data.code === 0) {
      console.log('獲取金融統計數據:', response.data.data);
      financialStats.value = response.data.data.total_investment;
      totalDividendUsdt.value = response.data.data.total_usdt;
      ElMessage({
        message: '獲取金融統計數據成功',
        type: 'success'
      });
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error('獲取金融統計數據失敗:', error);
    ElMessage({
      message: '獲取金融統計數據失敗，請重試',
      type: 'error'
    });
  }
}


watch([() => form.value.source_amount, () => currentRate.value], ([newSourceAmount, newRate]) => {
  if (form.value.operation_type === 'buy' || form.value.operation_type === 'sell') {
    const sourceAmount = parseFloat(newSourceAmount);
    const rate = parseFloat(newRate);
    if (!isNaN(sourceAmount) && !isNaN(rate)) {
      form.value.target_amount = (sourceAmount / rate).toFixed(4);
    }
  }
});
</script>