<template>
  <div class="display-data" ref="displayData">
    <div class="data-display">
      <template v-if="isUrl(data) && !iframeHidden">
        <iframe :src="`${data}?t=${new Date().getTime()}`" class="full-page-iframe" frameborder="0" style="background-color: #1a1a1a;"></iframe>
        <button class="toggle-iframe-button" @click="toggleIframe">
          <i class="el-icon-close"></i>
        </button>
      </template>
      <template v-else>
        <p v-if="data">{{ data }}</p>
        <p v-else>{{ $t('message.noData') }}</p>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'DisplayData',
  setup() {
    const route = useRoute();
    const channel = ref(route.params.channel || '0');
    const data = ref('');
    const displayData = ref(null);
    const activeNames = ref([]);
    const iframeHidden = ref(false);
    let intervalId = null;
    const previousUpdateCount = ref(null);
    const wasFullScreen = ref(false);

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://order.pcdao.org/api/display_data/${channel.value}`);
        console.log(response.data);
        
        if (response.data.display_data) {
          const displayData = response.data.display_data;
          
          if (previousUpdateCount.value !== null && previousUpdateCount.value !== displayData.update_count) {
            wasFullScreen.value = !!document.fullscreenElement;
            if (isUrl(data.value)) {
              refreshIframe();
            }
          }
          previousUpdateCount.value = displayData.update_count;

          if (displayData.display_scheme === 'ORDER') {
            data.value = `https://order.pcdao.org/order/${displayData.order_number}`;
          } else if (displayData.display_scheme === 'URL') {
            if (displayData.content && displayData.content.startsWith('https')) {
              if (displayData.content !== data.value) {
                data.value = displayData.content;
              }
            }
          } else if (displayData.display_scheme === 'JSON') {
            const newData = displayData.content || 'No data available';
            if (newData !== data.value) {
              data.value = newData;
            }
          } else {
            data.value = 'Invalid display scheme';
          }
        } else {
          data.value = 'No display data available';
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        data.value = 'Error fetching data';
      }
    };

    const isUrl = (string) => {
      try {
        return new URL(string).protocol.startsWith('http');
      } catch (_) {
        return false;
      }
    };

    const toggleIframe = () => {
      iframeHidden.value = !iframeHidden.value;
    };

    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        displayData.value.requestFullscreen().catch(err => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen();
      }
    };

    const handleKeydown = (event) => {
      if (event.ctrlKey && event.key === 'z') {
        event.preventDefault();
        toggleFullScreen();
      }
      if (event.ctrlKey && event.key === 'f') {
        event.preventDefault();
        toggleIframe();
      }
    };

    const refreshIframe = () => {
      const iframe = document.querySelector('.full-page-iframe');
      if (iframe) {
        const currentSrc = iframe.src;
        iframe.src = '';
        iframe.src = currentSrc;
      }
    };

    onMounted(() => {
      fetchData();
      intervalId = setInterval(fetchData, 5000);
      window.addEventListener('keydown', handleKeydown);

      if (wasFullScreen.value) {
        toggleFullScreen();
      }
    });

    onBeforeUnmount(() => {
      clearInterval(intervalId);
      window.removeEventListener('keydown', handleKeydown);
    });

    watch(() => route.params.channel, (newChannel) => {
      channel.value = newChannel;
      fetchData();
    });

    return {
      channel,
      data,
      displayData,
      activeNames,
      isUrl,
      iframeHidden,
      toggleIframe,
      refreshIframe,
    };
  },
};
</script>

<style scoped>
.display-data {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #2c2c2c;
  color: #f0f0f0;
  overflow: hidden;
}

.channel-input {
  margin-bottom: 20px;
}

.channel-input label {
  margin-right: 10px;
}

.channel-buttons {
  margin-bottom: 20px;
}

.channel-buttons button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #409EFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.channel-buttons button:hover {
  background-color: #66b1ff;
}

.data-display {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #343a40;
  padding: 0px;
  overflow: hidden;
}

.full-page-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.toggle-iframe-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-iframe-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.el-icon-close {
  font-size: 20px;
}
</style>
