import  api  from './api';

const memberService = {
  async getAllMembers(params = {}) {
    try {
      const {
        page = 1,
        pageSize = 10,
        name,
        phone,
        email,
        membershipLevelId
      } = params;

      const queryParams = {
        page,
        page_size: pageSize
      };

      if (name) queryParams.name = name;
      if (phone) queryParams.phone = phone;
      if (email) queryParams.email = email;
      if (membershipLevelId) queryParams.membership_level_id = membershipLevelId;

      const response = await api.get('/member/list', { params: queryParams });
      return response.data;
    } catch (error) {
      console.error('获取会员列表失败:', error);
      throw error;
    }
  },

  async createMember(memberData) {
    try {
      const response = await api.post('/member/create', memberData);
      return response.data;
    } catch (error) {
      console.error('创建会员失败:', error);
      throw error;
    }
  },

  async updateMember(memberData) {
    try {
      console.log('更新会员数据:', memberData);
      const response = await api.put('/member/update', { ...memberData });
      console.log('更新会员响应:', response.data);
      return response.data;
    } catch (error) {
      console.error('更新会员失败:', error);
      throw error;
    }
  },

  async getMemberDetails(memberId) {
    try {
      const response = await api.get(`/member/${memberId}`);
      return response.data;
    } catch (error) {
      console.error('获取会员详情失败:', error);
      throw error;
    }
  },

//   async deleteMember(memberId) {
//     try {
//       const response = await api.delete(`/api/member/${memberId}`);
//       return response.data;
//     } catch (error) {
//       console.error('删除会员失败:', error);
//       throw error;
//     }
//   },

  async getMembershipLevels() {
    try {
      const response = await api.get('/member/membership_levels');
      return response.data;
    } catch (error) {
      console.error('获取会员级别列表失败:', error);
      throw error;
    }
  }

};

export default memberService;