<template>
  <el-dialog :title="isEditing ? '编辑会员' : '创建会员'" v-model="dialogVisible" width="800px">
    <el-form :model="formData" :rules="rules" ref="form" :label-width="120">
      <el-row :gutter="20">
        <!-- 短字段两列布局 -->
        <el-col :span="12">
          <el-form-item label="会员ID" prop="member_id">
            <el-tag type="info" size="large" style="width: 100%; padding: 8px; text-align: left">{{ formData.member_id }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户ID" prop="user_id">
            <el-tag type="info" size="large" style="width: 100%; padding: 8px; text-align: left">{{ formData.user_id }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="推广码" prop="promo_code">
            <el-input v-model="formData.promo_code"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="佣金余额" prop="commission_balance">
            <el-input v-model="formData.commission_balance" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="phone">
            <el-input v-model="formData.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备用电话" prop="alt_phone">
            <el-input v-model="formData.alt_phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="WhatsApp" prop="whatsapp">
            <el-input v-model="formData.whatsapp"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Telegram" prop="telegram">
            <el-input v-model="formData.telegram"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出生日期" prop="birth_date">
            <el-date-picker v-model="formData.birth_date" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="国家" prop="country">
            <el-input v-model="formData.country"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账户名" prop="account_name">
            <el-input v-model="formData.account_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总买入金额" prop="total_buy_amount">
            <el-tag type="success" size="large" style="width: 100%; padding: 8px; text-align: left">{{ formData.total_buy_amount }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总卖出金额" prop="total_sell_amount">
            <el-tag type="danger" size="large" style="width: 100%; padding: 8px; text-align: left">{{ formData.total_sell_amount }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密钥" prop="secret_key">
            <el-tag type="warning" size="large" style="width: 100%; padding: 8px; text-align: left">{{ formData.secret_key }}</el-tag>
          </el-form-item>
        </el-col>

        <!-- 长字段单列布局 -->
        <el-col :span="24">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="formData.email"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="地址" prop="address">
            <el-input v-model="formData.address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formData.remark" type="textarea" :rows="2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="钱包地址" prop="wallet_address">
            <el-input v-model="formData.wallet_address"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">提交</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'MemberForm',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    memberData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const formData = ref({ ...props.memberData });
    const form = ref(null);
    const dialogVisible = ref(props.visible);

    watch(() => props.visible, (val) => {
      dialogVisible.value = val;
    });

    watch(() => dialogVisible.value, (val) => {
      emit('update:visible', val);
    });

    watch(() => props.memberData, (newData) => {
      formData.value = { ...newData };
    }, { immediate: true });

    const rules = {
      name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
      phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
    };

    const closeDialog = () => {
      dialogVisible.value = false;
    };

    const handleSubmit = () => {
      form.value.validate((valid) => {
        if (valid) {
          emit('submit', formData.value);
          closeDialog();
        }
      });
    };

    return {
      formData,
      form,
      rules,
      handleSubmit,
      dialogVisible,
      closeDialog
    };
  }
};
</script>

<style scoped>
.member-form {
  padding: 20px;
}
</style>