import  api  from './api';

const displayDataService = {
  // 创建或更新显示数据
  async createOrUpdateDisplayData(data) {
    try {
      const response = await api.post('/display_data', data);
      return response.data;
    } catch (error) {
      console.error('Error creating or updating display data:', error);
      throw error;
    }
  },

  // 更新显示数据
  async updateDisplayData(id, data) {
    try {
      const response = await api.put(`/display_data/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating display data:', error);
      throw error;
    }
  },

  // 删除显示数据
  async deleteDisplayData(id) {
    try {
      const response = await api.delete(`/display_data/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting display data:', error);
      throw error;
    }
  },

  // 获取显示数据
  async getDisplayData(channel, updateCount = 0) {
    try {
      const response = await api.get(`/display_data/${channel}`, {
        params: { update_count: updateCount }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching display data:', error);
      throw error;
    }
  },

  // 获取所有显示数据
  async getAllDisplayData() {
    try {
      const response = await api.get(`/display_data/all`);
      return response.data;
    } catch (error) {
      console.error('Error fetching all display data:', error);
      throw error;
    }
  },

  // 获取所有订单显示数据
  async getOrdersDisplayData() {
    try {
      const response = await api.get(`/display_data/orders`);
      return response.data;
    } catch (error) {
      console.error('Error fetching orders display data:', error);
      throw error;
    }
  }
};

export default displayDataService;