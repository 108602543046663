import  api, { publicApi } from './api';

/**
 * 创建价格报价
 * @param {Object} priceQuoteData - 包含价格报价信息的对象
 * @param {number} priceQuoteData.price - 价格
 * @param {string} priceQuoteData.direction - 方向
 * @param {string} priceQuoteData.level - 等级
 * @param {string} [priceQuoteData.currency='USDT'] - 货币类型，默认为 USDT
 * @param {number} [priceQuoteData.target_volume=0] - 目标交易量
 * @param {number} [priceQuoteData.sort_order=0] - 排序顺序
 * @param {number} [priceQuoteData.priority=2] - 优先级
 * @param {boolean} [priceQuoteData.is_active=true] - 是否激活
 * @param {string} [priceQuoteData.notes=''] - 备注
 * @returns {Promise<Object>} - 返回包含响应数据的 Promise
 */
export const createPriceQuote = async (priceQuoteData) => {
  try {
    const response = await api.post('/price_quote', {
      price: priceQuoteData.price,
      direction: priceQuoteData.direction,
      level: priceQuoteData.level,
      currency: priceQuoteData.currency || 'USDT',
      target_volume: priceQuoteData.target_volume || 0,
      sort_order: priceQuoteData.sort_order || 0,
      priority: priceQuoteData.priority || 2,
      is_active: priceQuoteData.is_active !== undefined ? priceQuoteData.is_active : true,
      notes: priceQuoteData.notes || '',
    });
    console.log('createPriceQuote response:', response);
    return response.data;
  } catch (error) {
    console.error('Error creating PriceQuote:', error);
    throw error;
  }
};

/**
 * 更新价格报价
 * @param {number} id - 价格报价的 ID
 * @param {Object} priceQuoteData - 包含更新信息的对象
 * @returns {Promise<Object>} - 返回包含响应数据的 Promise
 */
export const updatePriceQuote = async (id, priceQuoteData) => {
  try {
    const response = await api.put(`/price_quote/${id}`, priceQuoteData);
    return response.data;
  } catch (error) {
    console.error('Error updating PriceQuote:', error);
    throw error;
  }
};

/**
 * 获取所有价格报价
 * @returns {Promise<Object>} - 返回包含所有价格报价的响应数据的 Promise
 */
export const getAllPriceQuotes = async () => {
  try {
    const response = await api.get('/price_quotes');
    console.log('getAllPriceQuotes response:', response);
    return response.data;
  } catch (error) {
    console.error('Error retrieving PriceQuotes:', error);
    throw error;
  }
}; 


/**
 * 获取所有价格报价 (无需登录)
 * @returns {Promise<Object>} - 返回包含所有价格报价的响应数据的 Promise
 */
export const getAllPriceQuotesV2 = async () => {
  try {
    const response = await publicApi.get('/v2/price_quotes');
    console.log('getAllPriceQuotesV2 response:', response);
    return response.data;
  } catch (error) {
    console.error('Error retrieving PriceQuotes V2:', error);
    throw error;
  }
};
