import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { useUserStore } from './stores/user';

import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import { Magic } from 'magic-sdk'; // 引入 Magic SDK
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css'
import './assets/styles/global.css'; // 引入全局样式

import en from './locales/en';
import zh from './locales/zh';
import zhTW from './locales/zh-TW';

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);

const i18n = createI18n({
  locale: 'zh-TW',
  messages: {
    en,
    zh,
    'zh-TW': zhTW
  }
});
app.use(i18n);

const magic = new Magic('pk_live_5976002723CBF02C'); // 替換為您的 Magic 公鑰
export { magic };

app.use(ElementPlus);
app.use(router);

// 在應用掛載後初始化用戶存儲
const userStore = useUserStore();
userStore.loadUserData(); // 這會從 localStorage 恢復之前保存的數據
console.log('用戶數據已恢復:', userStore.$state); // 可以檢查恢復的數據

app.mount('#app');
