<template>
  <div id="app">
    <el-header v-if="!$route.meta.hideHeader">
      <div class="header-left">
        <img src="@/assets/logo.png" alt="Logo" class="logo" />
        <span class="title">{{ t("message.title") }}</span>
      </div>
      <div class="header-right">
        <el-select v-model="locale" :placeholder="t('message.language')" @change="changeLanguage" style="width: 100px;">
          <el-option label="English" value="en"></el-option>
          <el-option label="简体中文" value="zh"></el-option>
          <el-option label="繁體中文" value="zh-TW"></el-option>
        </el-select>
      </div>
    </el-header>
    <router-view />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useUserStore } from './stores/user';

export default {
  name: 'App',
  setup() {
    const { locale, t } = useI18n();
    const userStore = useUserStore();

    const changeLanguage = (newLocale) => {
      locale.value = newLocale;
    };

    return {
      locale,
      t,
      changeLanguage,
      userStore
    };
  }
};
</script>

<style>
/* 全局禁用滚动条 */
html, body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

/* 禁用 Webkit 浏览器的滚动条 */
::-webkit-scrollbar {
  display: none;
}

/* 禁用 Firefox 的滚动条 */
* {
  scrollbar-width: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: transparent;
  margin: 0;
  height: var(--header-height, 100px); /* 使用 CSS 变量设置头部高度 */
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.title {
  font-size: 22px;
  font-weight: bold;
}

.header-right {
  display: flex;
  align-items: center;
}

.el-icon-s-help {
  font-size: 24px;
  cursor: pointer;
}
</style>